import { State } from "../state";
import { Request } from "@/network/request";
// import Swal from "sweetalert2";
import i18n from "../../i18n";
import toast from "../../plugins/toast";

export default class agegroup {
  token = $cookies.get("token");
  tableData = {
    data: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },
      description: {
        name: "description",
        keys: ["description"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };

  getState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();

  async get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/age_group`,
      params: params,
      token: this.token,
    });

    await this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.ageGroups;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/age_group`,
      data: payload,
      token: this.token,
    });

    this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  edit(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/age_group/${id}`,
      data: payload,
      token: this.token,
    });

    this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/age_group/${id}`,
      token: this.token,
    });

    this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const ageGroup = new agegroup();
