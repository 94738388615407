<template>
  <v-row
    class="justify-center align-center"
    @keyup.enter="$emit('getData', data)"
    v-exit-keyup="clearData"
  >
    <v-col lg="4">
      <v-text-field
        v-model="data.name"
        :label="$t('serach-by-name')"
        dense
        rounded
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="4">
      <v-text-field
        v-model="data.serial"
        type="text"
        dense
        rounded
        :label="$t('serach-by-serial')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col v-if="ageGroupItems[0]" lg="4">
      <v-autocomplete
        height="30px"
        :search-input.sync="searchTerm"
        v-model="data.age_group_id"
        :label="$t('age group')"
        item-text="name"
        item-value="id"
        outlined
        dense
        rounded
        validate-on-blur
        deletable-chips
        chips
        :loading="ageGroup.get.loading"
        :items="ageGroupItems"
        :menu-props="{ closeOnClick: true }"
      >
        <template v-if="!stopInfinityScroll" v-slot:append-item>
          <div
            v-intersect="onIntersectAge"
            class="pa-4 teal--text justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col lg="4">
      <v-select
        v-model="data.type"
        :label="$t('serach-by-types')"
        :items="types"
        item-value="value"
        :item-text="$t('text')"
        dense
        rounded
        outlined
        :menu-props="{ closeOnClick: true }"
      ></v-select>
    </v-col>
    <v-col lg="4" cols="12" sm="6" md="4" v-if="date">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="data.created_from"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.created_from"
            :label="$t('from_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            dense
            rounded
            outlined
            clearable
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.created_from"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu1 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(data.created_from)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col lg="4" cols="12" sm="6" md="4" v-if="date">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="data.created_to"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.created_to"
            :label="$t('to_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            dense
            rounded
            clearable
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.created_to"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu2 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(data.created_to)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="mt-n8" lg="4">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
import { ageGroup } from "@/store/exercise/age-group";
export default {
  directives: {
    "exit-keyup": {
      bind(el, binding, vnode) {
        el.addEventListener("keyup", (event) => {
          if (event.key === "Escape") {
            binding.value(event);
          }
        });
      },
    },
  },
  props: ["ageItems", "individual", "types", "date"],
  data() {
    return {
      ageGroup,
      agePage: 0,
      searchTerm: "",
      ageGroupItems: [],
      menu1: false,
      menu2: false,
      sortItems: [
        {
          value: "asc",
          text: this.$t("acs"),
        },
        {
          value: "desc",
          text: this.$t("desc"),
        },
      ],
      data: {
        name: null,
        serial: null,
        description: null,
        age_group_id: null,
        individual_differences: null,
        type: null,
        created_from: null,
        created_to: null,
        sortby_created: null,
      },
    };
  },
  methods: {
    clearData() {
      this.data.serial = null;
      this.data = {};
      this.$emit("clearData");
    },
    setFromDate() {
      this.modal = false;
    },
    async onIntersectAge() {
      this.agePage += 1;
      await ageGroup.get({ page: this.agePage, name: this.searchTerm });
      this.ageGroupItems = this.ageGroupItems.concat(this.ageItems);
      console.log(this.ageGroupItems);
    },
  },
  computed: {
    stopInfinityScroll() {
      return ageGroup.tableData.data.length == 0;
    },
  },
  created() {
    this.onIntersectAge();
  },
  watch: {
    searchTerm(val) {
      if (ageGroup.get.loading) return;
      setTimeout(() => {
        this.agePage = 1;
        ageGroup.get({ page: this.agePage, name: val });
      }, 500);
    },
    "data.name": function(val) {
      if (val == "") {
        this.data.name = null;
      }
    },
    "data.serial": function(val) {
      if (val == "") {
        this.data.serial = null;
      }
    },
  },
};
</script>
